var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", { attrs: { id: "page-heading", "data-cy": "CourseHeading" } }, [
        _c(
          "span",
          {
            attrs: { id: "notification-heading" },
            domProps: {
              textContent: _vm._s(
                _vm.$t("studyTrackingWebApp.schoolNotification.home.title")
              ),
            },
          },
          [_vm._v("Notification")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-1" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-2 form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "from-date" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("studyTrackingWebApp.statistics.fromDate")
                  ),
                },
              },
              [_vm._v("From date")]
            ),
            _vm._v(" "),
            _c(
              "b-input-group",
              { staticClass: "mb-3" },
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-datepicker", {
                      staticClass: "form-control",
                      attrs: {
                        "aria-controls": "from-date",
                        name: "fromDate",
                        locale: _vm.currentLanguage,
                        "date-format-options": {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        },
                        max: _vm.searchData.toDate,
                        "button-only": "",
                        "today-button": "",
                        "reset-button": "",
                        "close-button": "",
                      },
                      model: {
                        value: _vm.searchData.fromDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "fromDate", $$v)
                        },
                        expression: "searchData.fromDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "form-control",
                  attrs: {
                    id: "from-date",
                    "data-cy": "fromDate",
                    type: "text",
                    name: "fromDate",
                    value: _vm.convertDateTimeFromServer(
                      _vm.searchData.fromDate
                    ),
                    autocomplete: "off",
                    readonly: "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-2 form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "to-date" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("studyTrackingWebApp.statistics.toDate")
                  ),
                },
              },
              [_vm._v("To date")]
            ),
            _vm._v(" "),
            _c(
              "b-input-group",
              { staticClass: "mb-3" },
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-datepicker", {
                      staticClass: "form-control",
                      attrs: {
                        "aria-controls": "to-date",
                        name: "toDate",
                        locale: _vm.currentLanguage,
                        "date-format-options": {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        },
                        min: _vm.searchData.fromDate,
                        "button-only": "",
                        "today-button": "",
                        "reset-button": "",
                        "close-button": "",
                      },
                      model: {
                        value: _vm.searchData.toDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "toDate", $$v)
                        },
                        expression: "searchData.toDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "form-control",
                  attrs: {
                    id: "to-date",
                    "data-cy": "toDate",
                    type: "text",
                    name: "toDate",
                    value: _vm.convertDateTimeFromServer(_vm.searchData.toDate),
                    autocomplete: "off",
                    readonly: "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "class-room-course" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("studyTrackingWebApp.classRoom.course")
                ),
              },
            },
            [_vm._v("Course")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchData.courseId,
                  expression: "searchData.courseId",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "class-room-course",
                "data-cy": "course",
                name: "course",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.searchData,
                      "courseId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  function ($event) {
                    return _vm.reloadClassRoom()
                  },
                ],
              },
            },
            [
              _c("option", {
                attrs: { selected: "" },
                domProps: { value: null },
              }),
              _vm._v(" "),
              _vm._l(_vm.courses, function (courseOption) {
                return _c(
                  "option",
                  {
                    key: courseOption.id,
                    domProps: { value: courseOption.id },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(courseOption.courseName) +
                        "\n        "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "class-room" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("studyTrackingWebApp.statistics.classRoom")
                ),
              },
            },
            [_vm._v("Class Room")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchData.classId,
                  expression: "searchData.classId",
                },
              ],
              staticClass: "form-control",
              attrs: {
                name: "classId",
                id: "class-room",
                "data-cy": "classId",
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.searchData,
                    "classId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", {
                attrs: { selected: "" },
                domProps: { value: null },
              }),
              _vm._v(" "),
              _vm._l(_vm.classRooms, function (classRoomOption) {
                return _c(
                  "option",
                  {
                    key: classRoomOption.id,
                    domProps: { value: classRoomOption.id },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(classRoomOption.className) +
                        "\n        "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "student-code" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("studyTrackingWebApp.schoolNotification.studentCode")
                ),
              },
            },
            [_vm._v("studentCode")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchData.studentCode,
                expression: "searchData.studentCode",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "studentCode",
              id: "student-code",
              "data-cy": "studentCode",
            },
            domProps: { value: _vm.searchData.studentCode },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.searchData, "studentCode", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-end" }, [
        _vm.hasAnyFunctional("00080201")
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: {
                  id: "school-notification",
                  "data-cy": "pushSchoolNotification",
                },
                on: {
                  click: function ($event) {
                    return _vm.openPushNotification(true)
                  },
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "fa-paper-plane" } }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "studyTrackingWebApp.schoolNotification.home.schoolNotification"
                        )
                      ),
                    },
                  },
                  [_vm._v(" schoolNotification")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAnyFunctional("00080202")
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: {
                  id: "flexible-notification",
                  "data-cy": "pushFlexibleNotification",
                },
                on: {
                  click: function ($event) {
                    return _vm.openPushNotification(false)
                  },
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "fa-paper-plane" } }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "studyTrackingWebApp.schoolNotification.home.flexibleNotification"
                        )
                      ),
                    },
                  },
                  [_vm._v(" flexibleNotification")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAnyFunctional("00080203")
          ? _c(
              "button",
              {
                staticClass: "btn btn-success mr-2",
                on: {
                  click: function ($event) {
                    return _vm.openConfirmExportExcel()
                  },
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "fa-file-excel" } }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "studyTrackingWebApp.schoolNotification.home.exportExcel"
                        )
                      ),
                    },
                  },
                  [_vm._v("exportExcel")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info mr-2",
            attrs: { disabled: _vm.isFetching },
            on: { click: _vm.handleSyncList },
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: "fa-search", spin: _vm.isFetching },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("studyTrackingWebApp.schoolNotification.home.search")
                  ),
                },
              },
              [_vm._v("Refresh List")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.isFetching && _vm.notifications && _vm.notifications.length === 0
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "studyTrackingWebApp.schoolNotification.home.notFound"
                    )
                  ),
                },
              },
              [_vm._v("No notifications found")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.notifications && _vm.notifications.length > 0
        ? _c("div", { staticClass: "table-responsive table-scroll" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered table-hover",
                attrs: { "aria-describedby": "notifications" },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "studyTrackingWebApp.schoolNotification.id"
                              )
                            ),
                          },
                        },
                        [_vm._v("ID")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("notificationType")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.notificationType"
                                )
                              ),
                            },
                          },
                          [_vm._v("notificationType")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "notificationType",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("notificationID")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.notificationID"
                                )
                              ),
                            },
                          },
                          [_vm._v("notificationID")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "notificationID",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("createdDate")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.createdDate"
                                )
                              ),
                            },
                          },
                          [_vm._v("createdDate")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "createdDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("studentCode")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.studentCode"
                                )
                              ),
                            },
                          },
                          [_vm._v("studentCode")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "studentCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("userLogin")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.userLogin"
                                )
                              ),
                            },
                          },
                          [_vm._v("userLogin")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "userLogin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("title")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.title"
                                )
                              ),
                            },
                          },
                          [_vm._v("title")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "row" },
                        on: {
                          click: function ($event) {
                            return _vm.changeOrder("message")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.message"
                                )
                              ),
                            },
                          },
                          [_vm._v("message")]
                        ),
                        _vm._v(" "),
                        _c("jhi-sort-indicator", {
                          attrs: {
                            "current-order": _vm.paging.propOrder,
                            reverse: _vm.paging.reverse,
                            "field-name": "message",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "row" } }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.notifications, function (notification, index) {
                    return _c(
                      "tr",
                      {
                        key: notification.id,
                        class: { "row-selected": index === _vm.rowSelected },
                        attrs: { "data-cy": "entityTable" },
                        on: {
                          click: function ($event) {
                            return _vm.selectRow(index)
                          },
                        },
                      },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.paging.itemsPerPage * (_vm.paging.page - 1) +
                                index +
                                1
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            notification.notificationType === "CHECKIN"
                              ? _c(
                                  "b-badge",
                                  {
                                    attrs: { variant: "primary" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "studyTrackingWebApp.NotificationType." +
                                            notification.notificationType
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(notification.notificationType) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            notification.notificationType === "CHECKOUT"
                              ? _c(
                                  "b-badge",
                                  {
                                    attrs: { variant: "success" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "studyTrackingWebApp.NotificationType." +
                                            notification.notificationType
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(notification.notificationType) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            notification.notificationType === "WARNING"
                              ? _c(
                                  "b-badge",
                                  {
                                    attrs: { variant: "warning" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "studyTrackingWebApp.NotificationType." +
                                            notification.notificationType
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(notification.notificationType) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            notification.notificationType === "SYSTEM"
                              ? _c(
                                  "b-badge",
                                  {
                                    attrs: { variant: "danger" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "studyTrackingWebApp.NotificationType." +
                                            notification.notificationType
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(notification.notificationType) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            notification.notificationType === "SMS"
                              ? _c(
                                  "b-badge",
                                  {
                                    attrs: { variant: "danger" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "studyTrackingWebApp.NotificationType." +
                                            notification.notificationType
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(notification.notificationType) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(notification.notificationID))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatFullTime")(notification.createdDate)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(notification.studentCode))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(notification.userLogin))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(notification.title))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(notification.message))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.detailEntity",
                                      modifiers: { detailEntity: true },
                                    },
                                  ],
                                  staticClass: "btn btn-success btn-sm edit",
                                  attrs: { "data-cy": "entityEditButton" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prepareDetail(notification.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "eye" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "d-none d-md-inline",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("entity.action.view")
                                        ),
                                      },
                                    },
                                    [_vm._v("View")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notifications && _vm.notifications.length > 0,
              expression: "notifications && notifications.length > 0",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c("jhi-item-count", {
                attrs: {
                  page: _vm.paging.page,
                  total: _vm.paging.queryCount,
                  itemsPerPage: _vm.paging.itemsPerPage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  size: "md",
                  "total-rows": _vm.paging.totalItems,
                  "per-page": _vm.paging.itemsPerPage,
                  change: _vm.loadPage(_vm.paging.page),
                },
                model: {
                  value: _vm.paging.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.paging, "page", $$v)
                  },
                  expression: "paging.page",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "detailEntity", attrs: { id: "detailEntity", size: "lg" } },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c(
              "span",
              {
                attrs: {
                  id: "studyTrackingWebApp.sent.home.title",
                  "data-cy": "courseDeleteDialogHeading",
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("studyTrackingWebApp.sent.home.title")
                  ),
                },
              },
              [_vm._v("Sent detail")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("dl", { staticClass: "row jh-entity-details" }, [
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.notificationType"
                          )
                        ),
                      },
                    },
                    [_vm._v("notificationType")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "dd",
                  [
                    _vm.notificationDetail.notificationType === "CHECKIN"
                      ? _c(
                          "b-badge",
                          {
                            attrs: { variant: "primary" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.NotificationType." +
                                    _vm.notificationDetail.notificationType
                                )
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.notificationDetail.notificationType
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.notificationDetail.notificationType === "CHECKOUT"
                      ? _c(
                          "b-badge",
                          {
                            attrs: { variant: "success" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.NotificationType." +
                                    _vm.notificationDetail.notificationType
                                )
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.notificationDetail.notificationType
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.notificationDetail.notificationType === "WARNING"
                      ? _c(
                          "b-badge",
                          {
                            attrs: { variant: "warning" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.NotificationType." +
                                    _vm.notificationDetail.notificationType
                                )
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.notificationDetail.notificationType
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.notificationDetail.notificationType === "SYSTEM"
                      ? _c(
                          "b-badge",
                          {
                            attrs: { variant: "danger" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.NotificationType." +
                                    _vm.notificationDetail.notificationType
                                )
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.notificationDetail.notificationType
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.notificationID"
                          )
                        ),
                      },
                    },
                    [_vm._v("notificationID")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.notificationDetail.notificationID)),
                  ]),
                ]),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.createdDate"
                          )
                        ),
                      },
                    },
                    [_vm._v("createdDate")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatFullTime")(
                          _vm.notificationDetail.createdDate
                        )
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.studentCode"
                          )
                        ),
                      },
                    },
                    [_vm._v("studentCode")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.notificationDetail.studentCode)),
                  ]),
                ]),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.userLogin"
                          )
                        ),
                      },
                    },
                    [_vm._v("userLogin")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.notificationDetail.userLogin)),
                  ]),
                ]),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("studyTrackingWebApp.schoolNotification.title")
                        ),
                      },
                    },
                    [_vm._v("title")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [_vm._v(_vm._s(_vm.notificationDetail.title))]),
                ]),
                _vm._v(" "),
                _c("dt", [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.message"
                          )
                        ),
                      },
                    },
                    [_vm._v("message")]
                  ),
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [_vm._v(_vm._s(_vm.notificationDetail.message))]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive table-scroll" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered table-hover",
                  attrs: { "aria-describedby": "sents" },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.sent.id")
                              ),
                            },
                          },
                          [_vm._v("ID")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.sent.status")
                              ),
                            },
                          },
                          [_vm._v("status")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.sent.sender")
                              ),
                            },
                          },
                          [_vm._v("sender")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.sent.receiving")
                              ),
                            },
                          },
                          [_vm._v("receiving")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.sent.cause")
                              ),
                            },
                          },
                          [_vm._v("cause")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.notificationDetail.sents,
                      function (sent, index) {
                        return _c(
                          "tr",
                          { attrs: { "data-cy": "entityTable" } },
                          [
                            _c("td", [_vm._v(_vm._s(index + 1))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                sent.status === "NONE"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "dark" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                sent.status === "READY"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          pill: "",
                                          variant: "secondary",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                sent.status === "IN_PROGRESS"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "primary" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                sent.status === "RETRY"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "warning" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                sent.status === "DONE"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "success" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                sent.status === "ERROR"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "danger" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "studyTrackingWebApp.SendStatus." +
                                                sent.status
                                            )
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(sent.status))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(sent.sender))]),
                            _vm._v(" "),
                            sent.token
                              ? _c("td", [_vm._v(_vm._s(sent.token))])
                              : _vm._e(),
                            _vm._v(" "),
                            sent.topic
                              ? _c("td", [_vm._v(_vm._s(sent.topic))])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(sent.cause))]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                domProps: {
                  textContent: _vm._s(_vm.$t("entity.action.close")),
                },
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("close")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "push-school-notification",
            "hide-footer": "",
            lazy: "",
            "no-close-on-backdrop": true,
            size: "xl",
          },
        },
        [
          _c(
            "span",
            {
              attrs: {
                slot: "modal-title",
                "data-cy": "pushSchoolNotification",
                id: "push-school-notification",
              },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "studyTrackingWebApp.schoolNotification.home.schoolNotification"
                  )
                ),
              },
              slot: "modal-title",
            },
            [_vm._v("push-school-notification")]
          ),
          _vm._v(" "),
          _c("push-school-notification"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "push-flexible-notification",
            "hide-footer": "",
            lazy: "",
            "no-close-on-backdrop": true,
            size: "xl",
          },
        },
        [
          _c(
            "span",
            {
              attrs: {
                slot: "modal-title",
                "data-cy": "pushSchoolNotification",
                id: "push-flexible-notification",
              },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "studyTrackingWebApp.schoolNotification.home.flexibleNotification"
                  )
                ),
              },
              slot: "modal-title",
            },
            [_vm._v("push-flexible-notification")]
          ),
          _vm._v(" "),
          _c("push-flexible-notification"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "confirmExportExcel", attrs: { id: "confirmDialog" } },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c(
              "span",
              {
                attrs: { id: "confirm", "data-cy": "confirmDialogHeading" },
                domProps: {
                  textContent: _vm._s(_vm.$t("global.modalTitle.confirm")),
                },
              },
              [_vm._v("Confirm export excel")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", {
              attrs: { id: "jhi-confirm-dialog-heading" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "studyTrackingWebApp.schoolNotification.confirm.exportExcel"
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                domProps: {
                  textContent: _vm._s(_vm.$t("entity.action.cancel")),
                },
                on: {
                  click: function ($event) {
                    return _vm.closeConfirmExportExcel()
                  },
                },
              },
              [_vm._v("\n        Cancel\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  id: "jhi-confirm-dialog",
                  "data-cy": "confirmDialogButton",
                },
                domProps: {
                  textContent: _vm._s(_vm.$t("entity.action.accept")),
                },
                on: {
                  click: function ($event) {
                    return _vm.exportExcel()
                  },
                },
              },
              [_vm._v("\n        Delete\n      ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }